<template>
  <v-row justify="start">
    <v-col cols="12">
      <div class="title">{{ title }}</div>
    </v-col>
    <v-col
        v-for="item in catalogItems.filter(i => $store.state.app_catalog.filter_imported_goods ? (i.with_imported_goods || i.child_with_imported_goods) : true)"
        :key="item.id"
        cols="12"
        sm="6"
        lg="4"
        xl="3"
    >
      <CatalogListItem :item="item" :activeCatalogType="activeCatalogType" />
    </v-col>
  </v-row>
</template>

<script>
import * as catalogTypes from '@/common/catalogTypes'
import { catalogListMixin } from './mixins'
import CatalogListItem from './CatalogListItem'

export default {
  mixins: [catalogListMixin],
  components: { CatalogListItem },
  computed: {
    title: function() {
      return this.activeCatalogType == catalogTypes.structure
        ? 'Дерево номенклатуры'
        : 'Дерево ассортимента'
    }
  }
}
</script>
