<template>
  <v-row v-if="item" justify="start">
    <v-col cols="12">
      <Breadcrumbs :catalogId="catalogId" class="pa-0" remove-last/>
    </v-col>
    <v-col cols="12" class="item-container">
      <EditableTitle
          :item="item"
          :editable="editable && isAdmin"
          :handler="updateMethod"
      >
        <template #prepend>
          <div class="title">{{ titlePrepend }}:</div>
        </template>
      </EditableTitle>
      <v-row>
        <v-col cols="12" sm="6" lg="4" xl="3">
          <v-catalog-img class="item-image" aspect-ratio="1" :src="photo"/>
          <v-row>
            <v-col cols="12">
              <v-alert
                  v-if="hasUnverifiedPhoto"
                  type="info"
                  class="ma-0 mt-6"
                  dense
                  text
              >
                Изображение загружено и ожидает проверки
              </v-alert>
              <v-row v-if="!hasUnverifiedPhoto && hasImagesPermission">
                <v-col cols="12" class="pt-0">
                  <v-file-input
                      v-model="file"
                      accept="image/png, image/jpeg"
                      placeholder="Выберите файл"
                      prepend-icon="mdi-camera"
                      label="Изображение"
                      hide-details
                  ></v-file-input>
                </v-col>
                <v-col cols="12" align-self="center" class="pb-0">
                  <v-btn
                      @click.stop="uploadPhoto"
                      :disabled="!file"
                      color="primary"
                      block
                  >
                    Загрузить
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row class="item-info py-3">
            <!-- stock_id -->
            <v-col cols="6"><span class="subtitle-2">Код:</span></v-col>
            <v-col cols="6" class="body-2">{{ item.id }}</v-col>
            <!-- 1c_id -->
            <v-col cols="6"><span class="subtitle-2">Код 1С:</span></v-col>
            <v-col cols="6" class="body-2">{{ item._1c_id || 'нет' }}</v-col>
            <!-- structure -->
            <v-col cols="6" class="subtitle-2 text-truncate">
              Родительский элемент:
            </v-col>
            <v-col cols="6" class="body-2">
              <v-chip
                  v-if="editable && isAdmin"
                  @click.stop="parentDialogTreeSelectDisplay = true"
                  close-icon="mdi-pencil"
                  small
              >
                {{ parentItemName }}
              </v-chip>
              <router-link
                  v-else-if="item.parentItem"
                  :to="{
                  name: routes.catalogDetail,
                  params: {
                    catalogType: activeCatalogType,
                    catalogId: item.parentItem.id
                  }
                }"
              >
                {{ parentItemName }}
              </router-link>
              <div v-else>---</div>
            </v-col>

            <!-- enabled -->
            <v-col cols="6">
              <span class="subtitle-2">Выгружать на сайт:</span>
            </v-col>
            <v-col cols="6">
              <v-switch
                  @change="switchItem"
                  :input-value="item.enabled"
                  :disabled="!editable || updating || !isAdmin"
                  :loading="updating"
                  class="mt-0 pt-0"
                  hide-details
              ></v-switch>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col v-if="isDuplicateEnabled && editable && isAdmin" cols="6">
              <a
                  @click.stop="duplicateDialogTreeSelectDisplay = true"
                  class="dashed caption"
              >
                Это дубль?
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-overlay color="white" :value="updating" absolute>
        <v-progress-circular
            :size="50"
            color="indigo lighten-4"
            indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-col>
    <v-col cols="12">
      <v-tabs v-model="tab">
        <v-tab :key="tabs.category">
          Разделы ({{ childs.filter(n => $store.state.app_catalog.filter_imported_goods ? (n.with_imported_goods || n.child_with_imported_goods) : true).length }})
        </v-tab>
        <v-tab :key="tabs.products">
          Товары <span v-show="products.length">({{ products.length }})</span>
          <ProductEditorDialog
              :default-category="$store.state.app_catalog.active_catalog_type === catalogTypes.category ? $route.params.catalogId : null"
              :default-structure="$store.state.app_catalog.active_catalog_type === catalogTypes.structure ? $route.params.catalogId : null"
          >
            <template #activator="{on, attrs}">
              <v-btn
                  @click="tab = tabs.products"
                  v-on="on"
                  v-bind="attrs"
                  fab
                  x-small
                  color="primary"
                  class="mx-4"
              >
                <v-icon color="white">mdi-plus</v-icon>
              </v-btn>
            </template>
          </ProductEditorDialog>
        </v-tab>
        <!-- Разделы -->
        <v-tab-item
            :value="tabs.category"
            :transition="false"
            :reverse-transition="false"
        >
          <v-row v-if="childs.filter(n => $store.state.app_catalog.filter_imported_goods ? (n.with_imported_goods || n.child_with_imported_goods) : true).length" justify="start">
            <v-col
                v-for="child in childs.filter(n => $store.state.app_catalog.filter_imported_goods ? (n.with_imported_goods || n.child_with_imported_goods) : true)"
                :key="child.id"
                cols="12"
                sm="6"
                lg="4"
                xl="3"
            >
              <CatalogListItem
                  :item="child"
                  :activeCatalogType="activeCatalogType"
              />
            </v-col>
          </v-row>
          <div v-else class="font-weight-light text-center pt-10">
            Нет разделов
          </div>
        </v-tab-item>
        <!-- Товары -->
        <v-tab-item
            :value="tabs.products"
            :transition="false"
            :reverse-transition="false"
        >
          <v-row v-if="products.length" justify="start">
            <v-col
                v-for="product in products"
                :key="product.id"
                cols="12"
                sm="6"
                lg="4"
                xl="3"
            >
              <ProductListItem
                  :item="product"
                  :activeCatalogType="activeCatalogType"
              />
            </v-col>
            <v-col v-if="productsHasMore" cols="12">
              <v-btn
                  @click.stop="fetchProductsHadler"
                  :disabled="getStockProductsFetching"
                  color="indigo lighten-1"
                  dark
                  depressed
                  block
              >
                Загрузить ещё
              </v-btn>
            </v-col>
          </v-row>
          <div v-else>
            <v-row
                v-if="getStockProductsFetching"
                class="fill-height pt-10"
                align-content="center"
                justify="center"
            >
              <v-col class="text-center font-weight-light" cols="10">
                Загрузка данных...
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                    color="indigo lighten-4"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <div v-else class="font-weight-light text-center pt-10">
              Нет товаров
            </div>
          </div>
        </v-tab-item>
      </v-tabs>

      <!-- Родитель -->
      <DialogTreeSelect
          :value="parentDialogTreeSelectDisplay"
          :items="getStockRootItems"
          :itemSelectable="({ enabled }) => enabled"
          :active="item.parentItem ? [item.parentItem.id] : []"
          :open="getOpenedItems"
          @close="parentDialogTreeSelectDisplay = false"
          @confirm="parentDialogTreeSelectSuccess"
      >
        <template #title>Выберите новый родительский раздел</template>
        <template #label="{ item }">
          <span :class="!item.enabled && 'grey--text darken-3'">
            {{ item.name }}
          </span>
        </template>
      </DialogTreeSelect>
      <DialogConfirm
          :value="parentDialogConfirmDisplay"
          @close="parentDialogConfirmDisplay = false"
          @confirm="parentDialogConfirmSuccess"
      >
        <template #title>Подтвердите изменения</template>
        <template #text>
          <b>Папка номенклатуры</b>
          будет изменена, вы уверены?
        </template>
      </DialogConfirm>

      <!-- Дубликат -->
      <DialogTreeSelect
          v-if="isDuplicateEnabled && editable && isAdmin"
          :value="duplicateDialogTreeSelectDisplay"
          :items="getStockRootItems"
          :itemSelectable="({ enabled }) => enabled"
          @close="duplicateDialogTreeSelectDisplay = false"
          @confirm="duplicateDialogTreeSelectSuccess"
      >
        <template #title>Выберите папку для объединения</template>
        <template #label="{ item }">
          <span :class="!item.enabled && 'grey--text darken-3'">
            {{ item.name }}
          </span>
        </template>
      </DialogTreeSelect>
      <DialogConfirm
          v-if="isDuplicateEnabled"
          :value="duplicateDialogConfirmDisplay"
          @close="duplicateDialogConfirmDisplay = false"
          @confirm="duplicateDialogConfirmSuccess"
      >
        <template #title>Подтвердите изменения</template>
        <template #text>
          Данная папка будет объединена с указанной. Все дочерние папки и
          товары, будут перенесены.
          <br/>
          Вы уверены?
        </template>
      </DialogConfirm>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapActions} from 'vuex'
import permissions from '@/common/permissions'
import * as catalogTypes from '@/common/catalogTypes'
import {routes} from '@/router'
import imagesApi from '@/services/imagesApi'
import Breadcrumbs from './Breadcrumbs'
import EditableTitle from './EditableTitle'
import CatalogListItem from './CatalogListItem'
import ProductListItem from './ProductListItem'
import DialogTreeSelect from './DialogTreeSelect'
import DialogConfirm from './DialogConfirm'
import ProductEditorDialog from './product-editor/ProductEditorDialog'

const itemsPerPage = 50
const tabs = {
  category: 0,
  products: 1
}

export default {
  components: {
    Breadcrumbs,
    EditableTitle,
    CatalogListItem,
    ProductListItem,
    DialogTreeSelect,
    DialogConfirm,
    ProductEditorDialog,
  },
  props: {
    catalogId: Number
  },
  data: () => ({
    catalogTypes,
    tab: tabs.category,
    hasUnverifiedPhoto: false,
    productsIdList: [],
    productsPage: 1,
    file: null,
    // родитель
    parentDialogTreeSelectDisplay: false,
    parentDialogTreeSelectValue: null,
    parentDialogConfirmDisplay: false,
    // дубликат
    duplicateDialogTreeSelectDisplay: false,
    duplicateDialogTreeSelectValue: null,
    duplicateDialogConfirmDisplay: false
  }),
  created() {
    this.tabs = tabs
    this.routes = routes
    this.fetchImagesUnverified()
  },
  watch: {
    catalogId: function () {
      this.productsIdList = []
      this.productsPage = 1
      this.fetchProducts()
      this.fetchImagesUnverified()
    },
    tab: function () {
      this.fetchProducts()
    },
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUserHasPermission']),
    ...mapGetters('app_catalog', ['activeCatalogType']),
    ...mapGetters('stock_categories', [
      'getStockCategoryById',
      'getStockCategoriesUpdating',
      'getStockRootCategories',
      'getStockCategoryAncestorsById'
    ]),
    ...mapGetters('stock_tree', [
      'getStockTreeById',
      'getStockTreeUpdating',
      'getStockTreeAncestorsById',
      'getStockRootTree'
    ]),
    ...mapGetters('stock_products', [
      'getStockProductsByIdList',
      'getStockProductsFetching'
    ]),
    ...mapGetters('images', ['getUploading']),
    products() {
      let list = this.getStockProductsByIdList(this.productsIdList)

      if (this.$store.state.app_catalog.filter_imported_goods)
        list = list.filter(p => p.from_distributor)
      return list
    },
    item: function () {
      return catalogTypes.structure === this.activeCatalogType
          ? this.getStockCategoryById(this.catalogId)
          : this.getStockTreeById(this.catalogId)
    },
    childs: function () {
      return this.item && this.item.children ? this.item.children : []
    },
    productsHasMore: function () {
      const {products_count} = this.item
      return products_count > this.productsIdList.length
    },
    photo: function () {
      return this.item && this.item.preview
    },
    titlePrepend: function () {
      return catalogTypes.structure === this.activeCatalogType
          ? 'Раздел номенклатуры'
          : 'Раздел ассортимента'
    },
    updateMethod: function () {
      return catalogTypes.structure === this.activeCatalogType
          ? this.actionPatchStockCategory
          : this.actionPatchStockTree
    },
    updating: function () {
      return (
          this.getStockCategoriesUpdating ||
          this.getStockTreeUpdating ||
          this.getUploading
      )
    },
    editable: function () {
      return !this.item._1c_id
    },
    hasImagesPermission: function () {
      return this.getCurrentUserHasPermission(permissions.appImages)
    },
    isAdmin: function () {
      return this.getCurrentUserHasPermission(permissions.appCatalogAdmin)
    },
    getStockRootItems: function () {
      return catalogTypes.structure === this.activeCatalogType
          ? this.getStockRootCategories
          : this.getStockRootTree
    },
    getOpenedItems: function () {
      if (this.item) {
        return (catalogTypes.structure === this.activeCatalogType
                ? this.getStockCategoryAncestorsById(this.item.id)
                : this.getStockTreeAncestorsById(this.item.id)
        ).map(({id}) => id)
      }
      return []
    },
    isDuplicateEnabled: function () {
      return catalogTypes.structure === this.activeCatalogType
    },
    parentItemName: function () {
      return (
          (this.item && this.item.parentItem && this.item.parentItem.name) ||
          '---'
      )
    }
  },
  methods: {
    ...mapActions('stock_products', ['actionFetchStockProducts']),
    ...mapActions('stock_categories', [
      'actionPatchStockCategory',
      'actionDuplicateStockCategory',
      'actionFetchStockCategories'
    ]),
    ...mapActions('stock_tree', ['actionPatchStockTree']),
    ...mapActions('images', ['actionUploadImage']),
    fetchProductsHadler: function () {
      this.productsPage++
      this.fetchProducts()
    },
    fetchProducts: function () {
      if (this.tab === tabs.products) {
        const params = {
          items_per_page: itemsPerPage,
          page: this.productsPage,
        }
        if (this.$store.state.app_catalog.filter_imported_goods) {
          params['from_distributor'] = true;
        }
        switch (this.activeCatalogType) {
          case catalogTypes.structure:
            params['structure'] = this.catalogId
            break
          case catalogTypes.category:
            params['category'] = this.catalogId
            break
        }
        this.actionFetchStockProducts(params).then(({dataList}) => {
          this.productsIdList = _.uniq([
            ...this.productsIdList,
            ...dataList.map(item => item.id)
          ])
        })
      }
    },
    fetchImagesUnverified: function () {
      this.hasUnverifiedPhoto = false
      imagesApi
          .getModerate(this.activeCatalogType, {
            filterByTarget: this.catalogId,
            filterUnverified: true
          })
          .then(data => {
            this.hasUnverifiedPhoto = data.count > 0
          })
    },
    switchItem: function (value) {
      this.updateMethod({
        id: this.item.id,
        data: {enabled: value}
      })
    },
    uploadPhoto: function () {
      this.actionUploadImage({
        type: this.activeCatalogType,
        id: this.item.id,
        file: this.file
      }).then(() => {
        this.file = null
        this.fetchImagesUnverified()
      })
    },
    // перемещение
    parentDialogTreeSelectSuccess: function (value) {
      this.parentDialogTreeSelectDisplay = false
      this.parentDialogTreeSelectValue = value
      this.parentDialogConfirmDisplay = true
    },
    parentDialogConfirmSuccess: function () {
      const data = {
        id: this.item.id,
        data: {parent: this.parentDialogTreeSelectValue}
      }
      this.updateMethod(data).then(() => {
        this.parentDialogTreeSelectValue = null
        this.parentDialogConfirmDisplay = false
      })
    },
    // дубликат структуры
    duplicateDialogTreeSelectSuccess: function (value) {
      this.duplicateDialogTreeSelectDisplay = false
      this.duplicateDialogTreeSelectValue = value
      this.duplicateDialogConfirmDisplay = true
    },
    duplicateDialogConfirmSuccess: function () {
      const catalogId = this.duplicateDialogTreeSelectValue
      this.duplicateDialogConfirmDisplay = false
      this.duplicateDialogTreeSelectValue = null
      this.actionDuplicateStockCategory({
        id: this.item.id,
        target_id: catalogId
      }).then(() => {
        this.actionFetchStockCategories(true).then(() => {
          this.$router.push({
            name: routes.catalogDetail,
            params: {
              catalogType: catalogTypes.structure,
              catalogId
            }
          })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.item-container {
  position: relative;
}

.item-info {
  > .col {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
</style>
